<template>
    <div class="flex flex-wrap items-center justify-around gap-6 md:gap-8 lg:flex-nowrap">
        <span class="sim-text-large sim-font-medium text-nowrap">
            {{ $t('accessibility.socials.continue_via') }}
        </span>

        <div class="sim-customer-auth-socials">
            <BaseUiButton
                v-for="social in socials"
                :key="social.id"
                class="sim-customer-auth-socials__link sim-ghost-btn-hover"
                :class="buttonClass"
                :on-click="async () => await loginThirdParty(social.id)"
                variant="bare"
                size="none"
                target="_blank"
            >
                <span class="visually-hidden">
                    {{ social.ariaLabel }}
                </span>
                <component
                    :is="social.icon"
                    :size="social.size"
                    class="sim-customer-auth-socials__icon"
                    :class="`sim-customer-auth-socials__icon--${social.id}`"
                    aria-hidden
                />
            </BaseUiButton>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { IconFacebook, IconGoogle, IconSeznam, IconApple, IconVUT } from '#components'
import { SocialiteTypeEnum } from '@sim-api-enums/customer'

const {
    buttonClass,
} = defineProps<{
    buttonClass?: string
}>()

const { t } = useI18n()
const runtimeConfig = useRuntimeConfig()
const { loginThirdParty } = useAuth()

interface SocialsLink {
    ariaLabel: string
    icon: unknown,
    size?: number
    id: SocialiteTypeEnum
}

const socials = computed<SocialsLink[]>(() => [
    // {
    //     ariaLabel: getAriaLabelForSocial(t('accessibility.socials.facebook')),
    //     icon: IconFacebook,
    //     size: 30,
    //     id: 'facebook',
    // },
    {
        ariaLabel: getAriaLabelForSocial(t('accessibility.socials.google')),
        icon: IconGoogle,
        id: SocialiteTypeEnum.GOOGLE,
    },
    {
        ariaLabel: getAriaLabelForSocial(t('accessibility.socials.seznam')),
        icon: IconSeznam,
        id: SocialiteTypeEnum.SEZNAM,
    },
    // {
    //     ariaLabel: getAriaLabelForSocial(t('accessibility.socials.apple')),
    //     icon: IconApple,
    //     id: 'apple_id',
    // },
    {
        ariaLabel: getAriaLabelForSocial(t('accessibility.socials.vut')),
        icon: IconVUT,
        id: SocialiteTypeEnum.VUT,
        size: 30,
    },
])

/**
 * Returns the translated aria label for provided social network.
 * The provided parameter must be a translated string.
 *
 * @example
 * getAriaLabelForSocial(t('accessibility.facebook'))
 * // returns "Go to login via Facebook (Opens in new tab)"
 * @param social
 */
function getAriaLabelForSocial(social: string) {
    return `${t('accessibility.socials.login_via', [social])} (${t('accessibility.opens_in_new_tab')})`
}

</script>

<style lang="scss" scoped>
$link-padding: 0.5rem;

.sim-customer-auth-socials {
    display: flex;
    justify-content: space-between;
    align-items: center;

    flex-grow: 1;
    gap: 1rem;

    margin: - $link-padding;
    max-width: 15rem;
}

.sim-customer-auth-socials__link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $link-padding;
    border-radius: $sim-border-radius-xs;
    width: 2.875rem;
    height: 2.875rem;
}

.sim-customer-auth-socials__icon--facebook {
    color: #0866ff;
}

</style>
